import { createContext } from 'react'
import { NotificationListResponse } from '../services/types'

type NotificationContextType = {
  notification: NotificationListResponse | null
  setNotification: (value: NotificationListResponse | null) => void
}

export const NotificationContext = createContext<NotificationContextType>({
  notification: null,
  setNotification: () => {},
})
