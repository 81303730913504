import http from '../http'
import {
  Token,
  ConfirmResetPasswordParams,
  UpdatePasswordParams,
  AuthSocialSettingsResponse,
  AuthSocialResponse,
} from '../types'
import { API_URL } from './urls'

export const AuthApi = {
  login: async function (username: string, password: string): Promise<Token> {
    return await http.post(API_URL.login, {
      username,
      password,
    })
  },

  resetPassword: async function (email: string) {
    return await http.post(API_URL.resetPassword, { email: email })
  },

  confirmResetPassword: async function (data: ConfirmResetPasswordParams) {
    return await http.post(API_URL.confirmResetPassword, data)
  },

  updatePassword: async function (data: UpdatePasswordParams) {
    return await http.post(API_URL.updatePassword, data)
  },

  getSocialSettings: async function (): Promise<AuthSocialSettingsResponse> {
    return await http.get(API_URL.authSocialSettings)
  },

  facebookLogin: async function (data: {
    access_token: string
  }): Promise<Token> {
    return await http.post(API_URL.facebookLogin, data)
  },

  googleLogin: async function (data: { access_token: string }): Promise<Token> {
    return await http.post(API_URL.googleLogin, data)
  },

  linkedinLogin: async function (data: { code: string }): Promise<Token> {
    return await http.post(API_URL.linkedinLogin, data)
  },

  facebookConnect: async function (data: { access_token: string }) {
    return await http.post(API_URL.facebookConnect, data)
  },

  googleConnect: async function (data: {
    access_token: string
  }): Promise<Token> {
    return await http.post(API_URL.googleConnect, data)
  },

  linkedinConnect: async function (data: { code: string }) {
    return await http.post(API_URL.linkedinConnect, data)
  },

  getSocial: async function (): Promise<AuthSocialResponse> {
    return await http.get(API_URL.authSocial)
  },

  disconnectSocial: async function (id: number): Promise<any> {
    return await http.post(`${API_URL.authSocial}${id}/disconnect/`, {})
  },
}
