export const DEVICES = {
  mobile: '(max-width: 767px)',
  tablet: '(max-width: 1023px)',
  extremeSmall: '(min-width: 480px)',
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1499px)',
  mdMax: '(max-width: 767px)',
  lgMax: '(max-width: 1023px)',
  xlMax: '(max-width: 1279px)',
  xxlMax: '(max-width: 1498px)',
}

export const PLACEHOLDER_IMAGE = {
  landscape: '/static/imgs/placeholderImage-landscape.jpg',
  square: '/static/imgs/placeholderImage-square.png',
  agent: '/static/imgs/placeholderImage-agent.png',
}

export const NUMBER_ITEMS_PER_PAGE = [12, 24, 36]

export const NO_RESULTS_MSG =
  'Your current search has no matching results. Can you refine your search and try again.'

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const MAPBOX_STYLE =
  process.env.NEXT_PUBLIC_MAPBOX_STYLE || 'mapbox://styles/mapbox/light-v10'
export const MAPBOX_KEY =
  process.env.NEXT_PUBLIC_MAPBOX_KEY ||
  'pk.eyJ1IjoiYW50diIsImEiOiJja3g5eWpnM2EzanhxMnZxMzBhdDB4aXh6In0.p3O73aS1_jwv8bm8S2MwjQ'

export const GOOGLE_OAUTH_KEY = process.env.GOOGLE_OAUTH_KEY || ''
