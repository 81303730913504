import { removeCookie } from '../services/cookies'
import http from '../services/http'

export const addAuthorizationHeader = (token: string) => {
  if (token) {
    http.defaults.headers.common['Authorization'] = ''
    delete http.defaults.headers.common['Authorization']
    http.defaults.headers.common['Authorization'] = `Token ${token}`
  }
}

export const removeAuthorizationHeader = () => {
  removeCookie('token')
  http.defaults.headers.common['Authorization'] = ''
  delete http.defaults.headers.common['Authorization']
}
